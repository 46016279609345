/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
// import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// import InputAdornment from '@mui/material/InputAdornment';
import { Main } from 'layouts';
import { Footer } from 'layouts/Main/components';

import Container from 'components/Container';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-W1G8LXHX5C']
    })
  ]
})
const analyticsa = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})
analytics.page()
analyticsa.page()

const ApplicationNotAccepted = () => {
  const theme = useTheme();

  return (
    
      <Box>
   
      
    <Box
      minHeight={{ xs: 300, sm: 600 }}
      position={'relative'}
      sx={{
        backgroundColor: theme.palette.alternate.main,
        background:
          'url(https://i.imgur.com/7UJWkrd.jpg) no-repeat center',
        backgroundSize: 'cover',
        marginTop: -12,
        paddingTop: 12,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 1,
          height: 1,
          background: alpha('#161c2d', 0.4),
          zIndex: 1,
        }}
      />
      <Container position={'relative'} zIndex={2}>
        <Box>
          <Box marginBottom={4} data-aos="fade-up">
            <Typography
              variant="h3"
              align='center'
              gutterBottom
              sx={{
                fontWeight: 900,
                color: 'common.white',
              }}
            >
              Thank You For Your Interest
            </Typography>
            <Typography
              variant="h6"
              align='center'
              component="p"
              color="text.primary"
              sx={{
                fontWeight: 400,
                color: 'common.white',
              }}
            >
             Applications Are Not Accepted...
            </Typography>
          </Box>
        </Box>
      </Container>
      
  
    </Box>
    <br/>
    <Container maxWidth={0.9} marginTop={-5}>
    <Footer>
    </Footer>
    </Container>

    </Box>
    
  );
};

export default ApplicationNotAccepted;